// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.api-list {
  display: grid;
  width: 100%;
  max-width: var(--width-base);
  gap: 16px;
}
.api-list__item {
  display: grid;
  grid-template-columns: 50px 150px 1fr max-content;
  grid-template-rows: 100px;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 16px;
  border-radius: var(--radius-base);
  background-color: var(--color-third);
}
.api-list__id {
  justify-self: center;
}
.api-list__token {
  display: grid;
  position: relative;
  height: 100%;
  align-items: center;
}
.api-list__copy {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  font-size: 14px;
  color: var(--color-secondary);
  border-radius: var(--radius-base);
  cursor: pointer;
}
.api-list li {
  list-style-type: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ApiList/ApiList.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,WAAA;EACA,4BAAA;EACA,SAAA;AAAJ;AAEI;EACI,aAAA;EACA,iDAAA;EACA,yBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,SAAA;EACA,iCAAA;EACA,oCAAA;AAAR;AAGI;EACI,oBAAA;AADR;AAII;EACI,aAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;AAFR;AAKI;EACI,qBAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,YAAA;EACA,eAAA;EACA,6BAAA;EACA,iCAAA;EACA,eAAA;AAHR;AAMI;EAAI,qBAAA;AAHR","sourcesContent":[".api-list {\r\n\r\n    display: grid;\r\n    width: 100%;\r\n    max-width: var(--width-base);\r\n    gap: 16px;\r\n\r\n    &__item {\r\n        display: grid;\r\n        grid-template-columns: 50px 150px 1fr max-content;\r\n        grid-template-rows: 100px;\r\n        align-items: center;\r\n        justify-content: center;\r\n        padding: 16px;\r\n        gap: 16px;\r\n        border-radius: var(--radius-base);\r\n        background-color: var(--color-third);\r\n    }\r\n\r\n    &__id {\r\n        justify-self: center;\r\n    }\r\n\r\n    &__token {\r\n        display: grid;\r\n        position: relative;\r\n        height: 100%;\r\n        align-items: center;\r\n    }\r\n\r\n    &__copy {\r\n        display: inline-block;\r\n        position: absolute;\r\n        top: 0;\r\n        right: 0;\r\n        padding: 4px;\r\n        font-size: 14px;\r\n        color: var(--color-secondary);\r\n        border-radius: var(--radius-base);\r\n        cursor: pointer;\r\n    }\r\n\r\n    li {list-style-type: none}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
