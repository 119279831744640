import "./Header.scss";
import CustomLink from "../CustomLink/CustomLink";

const Header = () => {
    return(
        <header className="header">
            <div className="header__wrapper">
                <nav>
                    <ul>
                        <li>
                            <CustomLink to="/">Главная</CustomLink>
                        </li>
                        <li>
                            <CustomLink to="/catalog">Каталог</CustomLink>
                        </li>
                        <li>
                            <CustomLink to="/shops">Магазины</CustomLink>
                        </li>
                        <li>
                            <CustomLink to="/api-list">Api</CustomLink>
                        </li>
                        <li>
                            <CustomLink to="/auth">Войти</CustomLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;