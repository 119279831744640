import "./CatalogPage.scss";
import {useEffect, useState} from "react";
import WrapperPage from "../../components/WrapperPage/WrapperPage";
import Title from "../../components/Title/Title";
import axios from "axios";
/*import AxiosInstance from "../../AxiosInstance";*/
import Pager from "../../components/Pager/Pager";
import ItemPerPage from "../../components/ItemPerPage/ItemPerPage";
import CatalogItem from "../../components/CatalogItem/CatalogItem";
import Filters from "../../components/Filters/Filters";

const CatalogPage = () => {

    const classPage = "catalog";

    const filterObj =
        [
            {
                "label": "Балконный блок",
                "key": "balkon"
            },
            {
                "label": "Дверь",
                "key": "dver"
            },
            {
                "label": "Фурнитура",
                "key": "furnitura"
            },
            {
                "label": "Решетка",
                "key": "grid"
            },
            {
                "label": "Окно",
                "key": "okno"
            },
            {
                "label": "Перегородка",
                "key": "partition"
            },
            {
                "label": "Душевая кабина",
                "key": "shower"
            }
        ]

    const [data, setData] = useState([]);
    const [pager, setPager] = useState([]);
    const [quantity, setQuantity] = useState(40);
    const [filter, setFilter] = useState(filterObj);
    const [filterReturn, setFilterReturn] = useState([]);
    const [error, setError] = useState(null);
    const [current, setCurrent] = useState(0);

    useEffect(() => {
        fetchQuotes();
    }, [current, quantity, filterReturn]);

    const fetchQuotes = async () => {

        try {
            const response = await axios.get('http://dev.steklo-servis26.ru/api/variations', {
                auth: {
                    username: 'admin',
                    password: 'Anneta665'
                },
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {
                    page: current,
                    items_per_page: quantity,
                    type: filterReturn,
                },
            });

            setData(response.data.rows);
            setPager(response.data.pager);

            console.log(response);

        } catch (error) {
            console.error(error);
            setError('Error fetching data');
        }
    };

   /* useEffect(() => {
        fetchFilter();
    }, [filter]);

    const fetchFilter = async () => {

        try {
            //const response = await axios.get('http://dev.steklo-servis26.ru/jsonapi/commerce_product_variation_type/commerce_product_variation_type', {
            const response = await axios.get('http://dev.steklo-servis26.ru/api/variations/type', {
                auth: {
                    username: 'admin',
                    password: 'Anneta665'
                },
                headers: {
                    'Content-Type': 'application/json',
                    /!*'Authorization': 'Basic YWRtaW46QW5uZXRhNjY1',*!/
                },
                params: {},
            });

        } catch (error) {
            console.error(error);
            setError('Error fetching data');
        }
    };*/


    const handlePageChange = (newCurrent) => {
        setCurrent(newCurrent);
    };

    const quantityChangeHandler = (newQuantity) => {
        setQuantity(newQuantity);
    }

    const formChangeHandler = (filterActive) => {
        setFilterReturn(filterActive);
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <WrapperPage className={classPage}>

            <Title className={classPage}>Каталог</Title>

            <section className={"content" + (filter.length > 0 ? " content-aside" : '')}>

                {filter.length > 0 ? (
                    <aside className={"aside" + (classPage ? " " + classPage + "-aside" : '')}>
                        <Filters filter={filter} className={classPage} name="category" onChangeFormHandler={formChangeHandler} />
                    </aside>
                ) : ''}

                <div className="content__inner">
                    <div className={"action action-" + classPage}>
                        <Pager className={classPage} pagination={pager} onPageChange={handlePageChange}/>
                        <ItemPerPage className={classPage} quantity={quantity} onChangeHandler={quantityChangeHandler}/>
                    </div>

                    <div className="catalog">
                        {data.map(item => (
                            <CatalogItem
                                key={item.id}
                                sku={item.sku}
                                img={item.img}
                                title={item.title}
                                price={item.price}
                                url={item.url}
                                type={item.type}
                                id={item.id}
                                uuid={item.uuid}
                            />
                        ))}
                    </div>

                    <div className={"action action-" + classPage}>
                        <Pager className={classPage} pagination={pager} onPageChange={handlePageChange}/>
                        <ItemPerPage className={classPage} quantity={quantity} onChangeHandler={quantityChangeHandler}/>
                    </div>
                </div>

            </section>
        </WrapperPage>
    );
};

export default CatalogPage;