import "./FrontPage.scss";
import WrapperPage from "../../components/WrapperPage/WrapperPage";
/*import axios from "axios";*/

const FrontPage = () => {

    /*const response = axios.patch('http://185.22.61.73:8088/clients/3', {
        auth: {
            username: 'admin',
            password: 'password'
        },
        headers: {
            'Content-Type': 'application/json',
            /!*'Authorization': 'Basic YWRtaW46QW5uZXRhNjY1',*!/
        },
    });*/

    /*const data = {"firstname": "Hulio2"};

    const response = axios.patch('http://185.22.61.73:8088/clients/3', data, {
        auth: {
            username: 'admin',
            password: 'password'
        },
        headers: {
            'Content-Type': 'application/json',
            /!*'Authorization': 'Basic YWRtaW46QW5uZXRhNjY1',*!/
        },
    });

    console.log(response);*/

    return (
        <WrapperPage className="front">
            Главная страница с виджетами
        </WrapperPage>
    );
}

export default FrontPage;