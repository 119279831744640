// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fieldset {
  border: unset;
  padding: 0;
}

.form-item {
  display: grid;
  gap: 8px;
}

input[type=radio],
input[type=checkbox] {
  display: none;
}
input[type=radio]:checked ~ label::after,
input[type=checkbox]:checked ~ label::after {
  background-color: var(--color-secondary);
}

input[type=radio] ~ label,
input[type=checkbox] ~ label {
  position: relative;
  cursor: pointer;
  padding-left: 26px;
}
input[type=radio] ~ label::after,
input[type=checkbox] ~ label::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid var(--color-secondary);
  border-radius: 50%;
  transition: var(--transition-base);
}`, "",{"version":3,"sources":["webpack://./src/components/Base/Form.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,UAAA;AACJ;;AAGI;EACI,aAAA;EACA,QAAA;AAAR;;AAIA;;EAEI,aAAA;AADJ;AAKY;;EACI,wCAAA;AAFhB;;AAQA;;EAGI,kBAAA;EACA,eAAA;EACA,kBAAA;AANJ;AAQI;;EACI,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;EACA,2BAAA;EACA,wCAAA;EACA,kBAAA;EACA,kCAAA;AALR","sourcesContent":[".fieldset {\r\n    border: unset;\r\n    padding: 0;\r\n}\r\n\r\n.form {\r\n    &-item {\r\n        display: grid;\r\n        gap: 8px;\r\n    }\r\n}\r\n\r\ninput[type=\"radio\"],\r\ninput[type=\"checkbox\"] {\r\n    display: none;\r\n\r\n    &:checked {\r\n        & ~ label {\r\n            &::after {\r\n                background-color: var(--color-secondary);\r\n            }\r\n        }\r\n    }\r\n}\r\n\r\ninput[type=\"radio\"] ~ label,\r\ninput[type=\"checkbox\"] ~ label {\r\n\r\n    position: relative;\r\n    cursor: pointer;\r\n    padding-left: 26px;\r\n\r\n    &::after {\r\n        content: \"\";\r\n        display: inline-block;\r\n        position: absolute;\r\n        width: 18px;\r\n        height: 18px;\r\n        left: 0;\r\n        top: calc(50%);\r\n        transform: translateY(-50%);\r\n        border: 2px solid var(--color-secondary);\r\n        border-radius: 50%;\r\n        transition: var(--transition-base);\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
