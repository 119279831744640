import "../Footer/Footer.scss";

const Footer = () => {
    return(
        <footer className="footer">
            <div className="copyright">Nelset</div>
        </footer>
    );
}

export default Footer;