import './Filters.scss';
import '../Base/Form.scss';

const Filters = (props) => {

    const filters = props.filter;
    const classes = props.className;
    const category = props.name;

    const changeFormHandler = (event) => {
        props.onChangeFormHandler(event.target.value);
    }

    return(
        <fieldset className={classes ? "fieldset fieldset-" + classes : "fieldset"}>
            <form className={classes ? "form-item form-" + classes : "form-item"}>
            {filters.map(item => (
                <div className="input__item" key={item.key}>
                    <input
                        name={category}
                        value={item.key}
                        id={item.key}
                        type="radio"
                        onChange={changeFormHandler}
                    />
                    <label htmlFor={item.key}>{item.label}</label>
                </div>
            ))}
            </form>
        </fieldset>
    );
}
export default Filters;