import './ItemPerPage.scss';
import Select from "react-select";
import {useState} from "react";

const ItemPerPage = (props) => {

    const className = props.className;
    const [quantity, setQuantity] = useState(props.quantity);

    const options = [
        { value: 10, label: 10 },
        { value: 20, label: 20 },
        { value: 40, label: 40 },
        { value: 60, label: 60 },
        { value: 100, label: 100 }
    ]

    const currentItemIndex = options.findIndex(function(item) {
        return item.value === quantity;
    });
    const currentItemIndexResult = currentItemIndex !== -1 ? currentItemIndex : 0;


    const changeSelectHandler = (event) => {
        setQuantity(event.value);
        props.onChangeHandler(event.value);
        console.log(quantity);
    }

    return(
        <Select options={options}
                defaultValue={options[currentItemIndexResult]}
                className={className ? "items-per-page items-per-page-" + className : "items-per-page"}
                onChange={changeSelectHandler}
        />
    );
}

export default ItemPerPage;