import './SinglePage.scss';
import ApiList from "../../components/ApiList/ApiList";

import WrapperPage from '../../components/WrapperPage/WrapperPage';
import { useParams } from 'react-router-dom';

const classPage = "single-page";

const SinglePage = () => {
    return(
        <WrapperPage className={classPage}>
            <ApiList />
        </WrapperPage>
    );
}
export default SinglePage;