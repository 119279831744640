import './PriceSite.scss';
import axios from "axios";
import {useState} from "react";

const PriceSite = (props) => {

    const type = props.type;
    const uuid = props.uuid;
    const [price, setPrice] = useState(props.price);
    const [isPrice, setIsPrice] = useState(false);

    const priceChangeHandler = (event) => {
        setPrice(event.target.value);
        setIsPrice(true);
    };

    const priceRequestHandler = () => {

        const data = {
            "data": {
                "type": "commerce_product_variation--" + type,
                "id": uuid,
                "attributes": {
                    "price": {
                        "number": price,
                        "currency_code": "RUB"
                    }
                }
            }
        }

        const response = axios.patch('http://dev.steklo-servis26.ru/jsonapi/commerce_product_variation/' + type + '/' + uuid, data, {
            auth: {
                username: 'admin',
                password: 'Anneta665'
            },
            headers: {
                'Content-Type': 'application/vnd.api+json',
            },
        });

        setIsPrice(false);
    }

    return(
        <div className="catalog__price">
            <div className="catalog__price-label">Цена (сайт): </div>
            <input type="text" value={Math.floor(price)} onChange={priceChangeHandler} className="catalog__price-input" />
            {isPrice && <button type="button" onClick={priceRequestHandler} className="catalog__price-submit">ok</button> }
        </div>
    );
}

export default PriceSite;