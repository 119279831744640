// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --height-header: 60px;
}

.header {
  display: grid;
  width: 100%;
  align-items: center;
  background-color: var(--color-primary);
  height: var(--height-header);
}
.header__wrapper {
  padding: 0 var(--offset-base);
  height: 100%;
}
.header nav {
  height: inherit;
  align-items: center;
}
.header nav ul {
  display: grid;
  height: inherit;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  gap: 18px;
  list-style-type: none;
}
.header nav ul li a {
  padding: 8px;
  color: var(--color-reverse);
}
.header nav ul li a.active-link {
  color: var(--color-secondary);
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AAEA;EAEI,aAAA;EACA,WAAA;EACA,mBAAA;EACA,sCAAA;EACA,4BAAA;AAAJ;AAEI;EACI,6BAAA;EACA,YAAA;AAAR;AAGI;EAEI,eAAA;EACA,mBAAA;AAFR;AAIQ;EAEI,aAAA;EACA,eAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;EAEA,qBAAA;AAJZ;AAOgB;EACI,YAAA;EACA,2BAAA;AALpB;AAOoB;EACI,6BAAA;AALxB","sourcesContent":[":root {\r\n    --height-header: 60px;\r\n}\r\n\r\n.header {\r\n\r\n    display: grid;\r\n    width: 100%;\r\n    align-items: center;\r\n    background-color: var(--color-primary);\r\n    height: var(--height-header);\r\n\r\n    &__wrapper {\r\n        padding: 0 var(--offset-base);\r\n        height: 100%;\r\n    }\r\n\r\n    nav {\r\n\r\n        height: inherit;\r\n        align-items: center;\r\n\r\n        ul {\r\n\r\n            display: grid;\r\n            height: inherit;\r\n            grid-auto-flow: column;\r\n            grid-auto-columns: max-content;\r\n            align-items: center;\r\n            gap: 18px;\r\n\r\n            list-style-type: none;\r\n\r\n            li {\r\n                a {\r\n                    padding: 8px;\r\n                    color: var(--color-reverse);\r\n\r\n                    &.active-link {\r\n                        color: var(--color-secondary);\r\n                    }\r\n                }\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
