import Layout from './components/Layout/Layout';
import {Route, Routes} from "react-router-dom";
import CatalogPage from "./pages/CatalogPage/CatalogPage";
import ShopsPage from "./pages/ShopsPage/ShopsPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import FrontPage from "./pages/FrontPage/FrontPage"
import AuthPage from "./pages/AuthPage/AuthPage";
import SinglePage from "./pages/SinglePage/SinglePage";

function App() {
  return (
      <>
          <Routes>
              <Route path="/" element={<Layout />}>
                  <Route index element={<FrontPage />} />
                  <Route path="/catalog" element={<CatalogPage />} />
                  <Route path="/shops" element={<ShopsPage />} />
                  <Route path="/auth" element={<AuthPage />} />
                  <Route path="/api-list" element={<SinglePage />} />
                  <Route path="*" element={<NotFoundPage />} />
              </Route>
          </Routes>
      </>
  );
}

export default App;
