import './CatalogItem.scss';
import PriceSite from "../PriceSite/PriceSite";

const CatalogItem = (props) => {

    const price = Math.floor(props.price);

    return(
        <div className={'catalog__item catalog__item-' + props.id} data-type={props.type} data-price={price} data-uuid={props.uuid}>
            <div className="catalog__img">
                <img src={'http://dev.steklo-servis26.ru/' + props.img} alt={props.title} />
            </div>
            <div className="catalog__info">
                <div className="catalog__title">
                    {props.title}
                </div>
            </div>
            <PriceSite price={props.price} uuid={props.uuid} type={props.type} />
            <div className="catalog__sku">{props.sku}</div>
            <div className="catalog__url">
                <a href={props.url}>Каталог</a>
            </div>
        </div>
    );
}

export default CatalogItem;