// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-page {
  position: static;
}
.auth-form {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  display: grid;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/AuthPage/AuthPage.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR;AAEI;EACI,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EACA,aAAA;EACA,QAAA;AAAR","sourcesContent":[".auth {\r\n    &-page {\r\n        position: static;\r\n    }\r\n    &-form {\r\n        position: absolute;\r\n        left: 50%;\r\n        top: 40%;\r\n        transform: translate(-50%, -50%);\r\n        display: grid;\r\n        gap: 8px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
