import { useState, useEffect } from 'react';
import axios from 'axios';
import './ApiList.scss';
import Title from "../Title/Title";

const ApiList = () => {

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchQuotes();
    }, []);

    const fetchQuotes = async () => {
        try {
            const response = await axios.get('http://dev.steklo-servis26.ru/api/marketplaces', {
                /*auth: {
                    username: 'admin',
                    password: 'Anneta665'
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic YWRtaW46QW5uZXRhNjY1',
                },*/
                params: {},
            });

            console.log(response);

            setData(response.data.data);

        } catch (error) {
            console.error(error);
            setError('Error fetching data');
        }
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    const apiInstances = {};

    data.forEach((item) => {

        switch (item.type) {
            case 'yandex_market':
                apiInstances.yandexApi = axios.create({
                    baseURL: item.base_url,
                    headers: {
                        'Authorization': 'Bearer ' + item.token,
                    }
                });

            break;

            case 'ozon':
                apiInstances.ozonApi = axios.create({
                    baseURL: item.base_url,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + item.token,
                    }
                });
            break;

            default:
                console.log("Empty API");
            break;
        }
    });


    if (apiInstances.yandexApi) {
        apiInstances.yandexApi.get('/campaigns', {})
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.error('Error fetching Yandex Market data:', error);
        });
    }


    return (
        <>
            <Title>Список Api-токенов</Title>
            <ul className="api-list">
                {data.map(item => (
                    <li key={item.id} className={'api-list__item api-list__item-' + item.id}>
                        <div className="api-list__id">{item.id}</div>
                        <div className="api-list__type">{item.type}</div>
                        <div className="api-list__baseurl">{item.base_url}</div>
                        <div className="api-list__token">
                            {item.token}
                            <span className="api-list__copy">Copy</span>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
}

export default ApiList;
