// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pager-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.pager-list__item {
  display: grid;
  min-width: 25px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-base);
  background-color: var(--color-third);
  transition: var(--transition-base);
  cursor: pointer;
}
.pager-list__item.current {
  background-color: var(--color-secondary);
  color: var(--color-reverse);
}

.action {
  display: grid;
  gap: 16px;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/Pager/Pager.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,QAAA;AACJ;AACI;EACI,aAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,iCAAA;EACA,oCAAA;EACA,kCAAA;EACA,eAAA;AACR;AACQ;EACI,wCAAA;EACA,2BAAA;AACZ;;AAIA;EACI,aAAA;EACA,SAAA;EACA,8CAAA;EACA,8BAAA;AADJ","sourcesContent":[".pager-list {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    gap: 8px;\r\n\r\n    &__item {\r\n        display: grid;\r\n        min-width: 25px;\r\n        padding: 8px;\r\n        align-items: center;\r\n        justify-content: center;\r\n        border-radius: var(--radius-base);\r\n        background-color: var(--color-third);\r\n        transition: var(--transition-base);\r\n        cursor: pointer;\r\n\r\n        &.current {\r\n            background-color: var(--color-secondary);\r\n            color: var(--color-reverse);\r\n        }\r\n    }\r\n}\r\n\r\n.action {\r\n    display: grid;\r\n    gap: 16px;\r\n    grid-template-columns: max-content max-content;\r\n    justify-content: space-between;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
