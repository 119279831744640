// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog__img img {
  display: block;
  width: 100%;
  max-width: 100%;
  border-radius: var(--radius-base);
}`, "",{"version":3,"sources":["webpack://./src/components/CatalogItem/CatalogItem.scss"],"names":[],"mappings":"AAGQ;EACI,cAAA;EACA,WAAA;EACA,eAAA;EACA,iCAAA;AAFZ","sourcesContent":[".catalog {\r\n    &__img {\r\n\r\n        img {\r\n            display: block;\r\n            width: 100%;\r\n            max-width: 100% ;\r\n            border-radius: var(--radius-base);\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
