import WrapperPage from "../../components/WrapperPage/WrapperPage";
import Title from "../../components/Title/Title";
const NotFoundPage = () => {
    const classPage = "not-found";
    return(
        <WrapperPage className={classPage}>
            <Title className={classPage}>Страница не найдена</Title>
            Page Not Found
        </WrapperPage>
    );
}

export default NotFoundPage;