// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  margin-top: var(--offset-base);
  padding: var(--offset-base);
  background-color: var(--color-primary);
}
.footer .copyright {
  color: var(--color-reverse);
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,2BAAA;EACA,sCAAA;AACJ;AACI;EACI,2BAAA;AACR","sourcesContent":[".footer {\r\n    margin-top: var(--offset-base);\r\n    padding: var(--offset-base);\r\n    background-color: var(--color-primary);\r\n\r\n    .copyright {\r\n        color: var(--color-reverse);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
