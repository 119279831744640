// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  box-sizing: border-box;
  --color-primary: #474A51;
  --color-secondary: #087ea4;
  --color-third: #f3f4f6;
  --color-reverse: #ffffff;
  --offset-base: 32px;
  --width-base: 800px;
  --radius-base: 5px;
  --transition-base: 0.2s;
}

html {height: 100%;}

body {
  height: inherit;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {height: inherit;}

a {
  text-decoration: unset;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}

img {
  width: 100%;
  max-width: max-content;
}

.wrapper {
  display: grid;
  height: inherit;
  grid-template-rows: max-content 1fr max-content;
}

.page {
  padding-top: calc(var(--offset-base) / 2);
}

.container {
  padding: 0 var(--offset-base);
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,wBAAwB;EACxB,0BAA0B;EAC1B,sBAAsB;EACtB,wBAAwB;EACxB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA,MAAM,YAAY,CAAC;;AAEnB;EACE,eAAe;EACf,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA,OAAO,eAAe,CAAC;;AAEvB;EACE,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,UAAU;EACV;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,+CAA+C;AACjD;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[":root {\n  box-sizing: border-box;\n  --color-primary: #474A51;\n  --color-secondary: #087ea4;\n  --color-third: #f3f4f6;\n  --color-reverse: #ffffff;\n  --offset-base: 32px;\n  --width-base: 800px;\n  --radius-base: 5px;\n  --transition-base: 0.2s;\n}\n\nhtml {height: 100%;}\n\nbody {\n  height: inherit;\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n#root {height: inherit;}\n\na {\n  text-decoration: unset;\n}\n\nul {\n  margin: 0;\n  padding: 0;\n  li {\n    list-style-type: none;\n  }\n}\n\nimg {\n  width: 100%;\n  max-width: max-content;\n}\n\n.wrapper {\n  display: grid;\n  height: inherit;\n  grid-template-rows: max-content 1fr max-content;\n}\n\n.page {\n  padding-top: calc(var(--offset-base) / 2);\n}\n\n.container {\n  padding: 0 var(--offset-base);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
