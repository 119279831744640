// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog__price {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 8px;
}
.catalog__price-input {
  width: 100%;
  max-width: 70px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/PriceSite/PriceSite.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,8CAAA;EACA,QAAA;AAAJ;AAEI;EACI,WAAA;EACA,eAAA;EACA,kBAAA;AAAR","sourcesContent":[".catalog__price {\r\n\r\n    display: grid;\r\n    grid-template-columns: max-content max-content;\r\n    gap: 8px;\r\n\r\n    &-input {\r\n        width: 100%;\r\n        max-width: 70px;\r\n        text-align: center;\r\n    }\r\n\r\n    &-submit {\r\n\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
