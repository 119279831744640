import "./ShopsPage.scss";
import WrapperPage from "../../components/WrapperPage/WrapperPage";
import Title from "../../components/Title/Title";

const ShopsPage = () => {
    const classPage = "shops";
    return(
        <WrapperPage className={classPage}>
            <Title className={classPage}>Список магазинов</Title>
            Список всех магазинов
        </WrapperPage>
    );
}

export default ShopsPage;