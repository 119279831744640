// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog {
  display: grid;
  margin-bottom: var(--offset-base);
  grid-template-columns: repeat(8, 1fr);
  gap: 8px;
}
.catalog__item {
  display: grid;
}
.catalog-aside {
  margin-top: var(--offset-base);
}

.action + .catalog {
  margin-top: calc(var(--offset-base) / 2);
}

.content-aside {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: calc(var(--offset-base) * 2);
}

@media (max-width: 1440px) {
  .catalog {
    grid-template-columns: repeat(4, 1fr);
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/CatalogPage/CatalogPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iCAAA;EACA,qCAAA;EACA,QAAA;AACJ;AACI;EACI,aAAA;AACR;AAEI;EACI,8BAAA;AAAR;;AAIA;EACI,wCAAA;AADJ;;AAKI;EACI,aAAA;EACA,gCAAA;EACA,iCAAA;AAFR;;AAMA;EACI;IACI,qCAAA;EAHN;AACF","sourcesContent":[".catalog {\r\n    display: grid;\r\n    margin-bottom: var(--offset-base);\r\n    grid-template-columns: repeat(8, 1fr);\r\n    gap: 8px;\r\n\r\n    &__item {\r\n        display: grid;\r\n    }\r\n\r\n    &-aside {\r\n        margin-top: var(--offset-base);\r\n    }\r\n}\r\n\r\n.action + .catalog {\r\n    margin-top: calc(var(--offset-base) / 2);\r\n}\r\n\r\n.content {\r\n    &-aside {\r\n        display: grid;\r\n        grid-template-columns: 250px 1fr;\r\n        gap: calc(var(--offset-base) * 2);\r\n    }\r\n}\r\n\r\n@media(max-width: 1440px) {\r\n    .catalog {\r\n        grid-template-columns: repeat(4, 1fr);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
