import './WrapperPage.scss';

const WrapperPage = (props) => {

    const classes = 'article' + (props.className ? ' article-' + props.className : '');

    return(
        <article className={classes}>
            <div className="container">
                {props.children}
            </div>
        </article>
    )
}

export default WrapperPage;