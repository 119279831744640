import {Link, useMatch } from 'react-router-dom';
import "./CustomLink.scss";

const CustomLink = ({children, to, ...props}) => {

    const match = useMatch(to);
    const setActive = match ? 'active-link' : '';

    return(
        <Link to={to} className={setActive}>
            {children}
        </Link>
    );
}
export default CustomLink;