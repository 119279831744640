import { Outlet } from "react-router-dom";
import "./Layout.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Layout = () => {
    return(

        <div className="wrapper">
            <Header />
            <Outlet />
            <Footer />
        </div>

    );
}

export default Layout;