import './Pager.scss';
import { useState, useEffect } from "react";

const Pager = ({ pagination, className, onPageChange }) => {
    const total = pagination.total_pages;
    const [current, setCurrent] = useState(pagination.current_page);

    useEffect(() => {
        setCurrent(pagination.current_page);
    }, [pagination]);

    const pagerHandler = (index) => {
        setCurrent(index);
        if (onPageChange) {
            onPageChange(index);
        }
    };

    return (
        <div className={className ? "pager pager-" + className : "pager"}>
            <ul className="pager-list">
                {new Array(total).fill('').map((item, index) => (
                    <li className={index === current ? 'pager-list__item current' : 'pager-list__item'}
                        key={`pager-item-${index}`}
                        onClick={() => pagerHandler(index)}
                    >
                        {index + 1}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Pager;